
import service from '../service.js'
import qs from 'qs'

// 登录接口
export function login(data) {
    return service({
        method: 'post',
        url: '/api/login/login',
        data
    })
}

// 获取专业
export function getmajor() {
    return service({
        method: 'post',
        url: '/api/index/major'
    })
}

// 获取课程
export function getcourse(data) {
    return service({
        method: 'post',
        url: '/api/index/course',
		data
    })
}
// 获取模块
export function getmodule(data) {
    return service({
        method: 'post',
        url: '/api/index/module',
		data
    })
}
// 获取模块2
export function CourseData(data) {
    return service({
        method: 'post',
        url: '/api/index/CourseData',
		data
    })
}
// 试题导入
export function setexport(data) {
    return service({
        method: 'post',
        url: '/api/question/export',
		data
    })
}

// 试题上传
export function exupload(data) {
    return service({
        method: 'post',
        url: '/api/index/upload',
		data
    })
}

// 试题创建
export function excreate(data) {
    return service({
        method: 'post',
        url: '/api/question/create',
		data
    })
}


// 试题列表
export function listData(data) {
    return service({
        method: 'post',
        url: '/api/question/listData',
		data
    })
}


// 加入试题篮
export function caseAdd(data) {
    return service({
        method: 'post',
        url: '/api/question/caseAdd',
		data
    })
}

// 试题篮列表
export function caseList() {
    return service({
        method: 'post',
        url: '/api/question/caseList'
    })
}


// 文章列表
export function articleList(data) {
    return service({
        method: 'post',
        url: '/api/index/article',
		data
    })
}

// 试卷列表
export function getlistData(data) {
    return service({
        method: 'post',
        url: '/api/paper/listData',
		data
    })
}


// 提交纠错
export function reportError(data) {
    return service({
        method: 'post',
        url: '/api/question/report_error',
		data
    })
}


// 组卷数据
export function quickData(data) {
    return service({
        method: 'post',
        url: '/api/paper/quickData',
		data
    })
}

// 组卷
export function quickCreate(data) {
    return service({
        method: 'post',
        url: '/api/paper/quickCreate',
		data
    })
}

// 试卷类型
export function typeData(data) {
    return service({
        method: 'post',
        url: '/api/paper/typeData',
		data
    })
}

// 试卷上传
export function exportpaper(data) {
    return service({
        method: 'post',
        url: '/api/paper/export',
		data
    })
}

// 试卷列表
export function paperlist(data) {
    return service({
        method: 'post',
        url: '/api/paper/listData',
		data
    })
}
// 试卷详情
export function paperdetail(data) {
    return service({
        method: 'post',
        url: '/api/paper/detail',
		data
    })
}
// 生成试卷
export function papercreate(data) {
    return service({
        method: 'post',
        url: '/api/paper/create',
		data
    })
}

// 移出习题篮
export function caseDel(data) {
    return service({
        method: 'post',
        url: '/api/question/caseDel',
		data
    })
}


// 统考列表
export function ExamlistData(data) {
    return service({
        method: 'post',
        url: '/api/exam/listData',
		data
    })
}

// 班级列表
export function classroom(data) {
    return service({
        method: 'post',
        url: '/api/school/classroom',
		data
    })
}

// 创建统考
export function exam_create(data) {
    return service({
        method: 'post',
        url: '/api/exam/create',
		data
    })
}
// 学生列表
export function getstudentList(data) {
    return service({
        method: 'post',
        url: '/api/school/student',
		data
    })
}
// 试题详情
export function question_detail(data) {
    return service({
        method: 'post',
        url: '/api/question/detail',
		data
    })
}

// 统考详情
export function examInfo(data) {
    return service({
        method: 'post',
        url: '/api/exam/examInfo',
		data
    })
}

// 线下考试
export function offline_detail(data) {
    return service({
        method: 'post',
        url: '/api/exam/offline_detail',
		data
    })
}

// 创建测评
export function test_create(data) {
    return service({
        method: 'post',
        url: '/api/exam/test_create',
		data
    })
}

// 考试学员统计
export function exam_student(data) {
    return service({
        method: 'post',
        url: '/api/Statistics/exam_student',
		data
    })
}

// 考试数据
export function exam_data(data) {
    return service({
        method: 'post',
        url: '/api/Statistics/exam_data',
		data
    })
}

// 考试数据
export function exam_list_data(data) {
    return service({
        method: 'post',
        url: '/api/Statistics/exam_list_data',
		data
    })
}


// 学校信息
export function schoolData(data) {
    return service({
        method: 'post',
        url: '/api/school/schoolData',
		data
    })
}

// IP信息
export function getIpv4(data) {
    return service({
        method: 'post',
        url: '/api/login/getIpv4',
		data
    })
}

// 会员中心
export function userInfo(data) {
    return service({
        method: 'post',
        url: '/api/user/userInfo',
		data
    })
}

// 测评列表
export function listTest(data) {
    return service({
        method: 'post',
        url: '/api/exam/listTest',
		data
    })
}

// 单个学员
export function exam_student_score(data) {
    return service({
        method: 'post',
        url: '/api/Statistics/exam_student_score',
		data
    })
}

// 成绩打印
export function exam_student_detail(data) {
    return service({
        method: 'post',
        url: '/api/exam/exam_student_detail',
        data
    })
}

// 修改考试状态
export function changestatus(data) {
    return service({
        method: 'post',
        url: '/api/exam/status',
        data
    })
}

// 修改考试状态
export function student_upload(data) {
    return service({
        method: 'post',
        url: '/api/school/student_upload',
        data
    })
}


// 上传试卷
export function paper_upload(data) {
    return service({
        method: 'post',
        url: '/api/paper/upload',
        data
    })
}

// 审核平台试题列表
export function listaudit(data) {
    return service({
        method: 'post',
        url: '/api/question/listaudit',
        data
    })
}

// 审核平台试题
export function audit_status(data) {
    return service({
        method: 'post',
        url: '/api/question/audit_status',
        data
    })
}

// 审核平台试卷列表
export function paper_listaudit(data) {
    return service({
        method: 'post',
        url: '/api/paper/listaudit',
        data
    })
}

// 审核平台试卷
export function paper_audit_status(data) {
    return service({
        method: 'post',
        url: '/api/paper/audit_status',
        data
    })
}






// 修改学生信息
export function student_update(data) {
    return service({
        method: 'post',
        url: '/api/school/student_update',
        data
    })
}


// 删除学生
export function student_del(data) {
    return service({
        method: 'post',
        url: '/api/school/student_del',
        data
    })
}


// 删除统考/测评
export function exam_delect(data) {
    return service({
        method: 'post',
        url: '/api/exam/delete',
        data
    })
}

// 修改
export function error_update(data) {
    return service({
        method: 'post',
        url: '/api/question/error_update',
        data
    })
}

// 判断修改
export function report_error(data) {
    return service({
        method: 'post',
        url: '/api/question/report_error',
        data
    })
}

// 审核列表
export function error_list(data) {
    return service({
        method: 'post',
        url: '/api/question/error_list',
        data
    })
}

// 纠错列表
export function error_log(data) {
    return service({
        method: 'post',
        url: '/api/question/error_log',
        data
    })
}

// 审核详情
export function error_detail(data) {
    return service({
        method: 'post',
        url: '/api/question/error_detail',
        data
    })
}

// 消息列表
export function message(data) {
    return service({
        method: 'post',
        url: '/api/user/message',
        data
    })
}


// 积分列表
export function integral(data) {
    return service({
        method: 'post',
        url: '/api/user/integral',
        data
    })
}


// 更新用户信息
export function updates(data) {
    return service({
        method: 'post',
        url: '/api/user/updates',
        data
    })
}
// 更新用户信息
export function withdraw(data) {
    return service({
        method: 'post',
        url: '/api/user/withdraw',
        data
    })
}

// 资讯详情
export function artidetail(data) {
    return service({
        method: 'post',
        url: '/api/index/ArticleDetail',
        data
    })
}

// 删除卷子
export function paper_delete(data) {
    return service({
        method: 'post',
        url: '/api/paper/delete',
        data
    })
}

// 卷子记录
export function paper_log(data) {
    return service({
        method: 'post',
        url: '/api/paper/paper_log',
        data
    })
}

// 班级总览
export function classroom_data(data) {
    return service({
        method: 'post',
        url: '/api/statistics/classroom_data',
        data
    })
}

// 正确错误率
export function question_data(data) {
    return service({
        method: 'post',
        url: '/api/statistics/question_data',
        data
    })
}


